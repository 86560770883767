import React, { Component } from 'react';

class UserCredits extends Component {
    constructor(props) {
        super(props);
        this.onClickAddCredits = this.onClickAddCredits.bind(this)
    }
    onClickAddCredits = () => {
        const stripe = window.Stripe('pk_test_YZzdj26IiMFseKintMNbgfGK00jqujG10u');
        stripe.redirectToCheckout({
            items: [
                // Replace with the ID of your SKU
                { sku: 'sku_FdJFwnCaIjRUnP', quantity: 1 }
            ],
            successUrl: 'https://plug-and-play-ai.netlify.com/menu',
            cancelUrl: 'https://plug-and-play-ai.netlify.com/',
        }).then(({ error }) => {
            alert(error)
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `error.message`.
        });
    }

    render() {
        const {creditsUsed, creditsAvailable} = this.props
            return (
                <div className="col-md-4 credits">
                    <h2 className="credit-value">Balance: {creditsUsed} used out of {creditsAvailable} USD</h2>
                    <a href="javascript:void(0)" className="add-credit" onClick={this.onClickAddCredits}>+ Add Credit</a>
                </div>
            );
        }
}

export default UserCredits;
