export const ROUTES = {
         HOME: "/",
         MENU: "/menu",
         UPLOAD: "/upload",
         BUILD: "/build",
         RESULTS: "/results"
       };

export const GOOGLE_CLIENT_ID = "239172359367-03svlq617o3oib45ilnu8p46f28us9nl.apps.googleusercontent.com"
export const GOOGLE_CLIENT_SECRET = "dF1ryiaVvcQSap_k3VJFV2HM"
export const AMAZON_COGNITO_POOLDATA = {
  UserPoolId: 'ap-southeast-1_9E1B6ZQuA',
  ClientId: '1dkgsm34mrn1ngdolp3rav79gp'
};
export const API_BASE_URL = "https://kdgkmjtzm4.execute-api.ap-southeast-1.amazonaws.com/DEV"

export const INITIAL_UPLOAD_BOX = {
    file: null,
    filename: "",
    status: "",
    message: "",
    classCard: "upload-card-body",
    classBorder: "",
    uploaded: false
  };

export const particlesOptions = {
  particles: {
    number: {
      value: 100
    },
    size: {
      value: 3
    },
    opacity: {
      value: 0.1,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false
      }
    },
    line_linked: {
      enable: true,
      distance: 200,
      color: "#ffffff",
      opacity: 0.3,
      width: 1
    },
    move: {
      enable: true,
      speed: 1,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200
      }
    }
  },
  interactivity: {
    events: {
      onhover: {
        enable: false,
        mode: "repulse"
      }
    }
  }
};

