import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import menuPage from './pages/Menu/reducer'
import uploadPage from './pages/Upload/reducer'
import buildPage from './pages/Build/reducer'
import resultPage from './pages/Results/reducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    menuPage: menuPage,
    uploadPage: uploadPage,
    buildPage: buildPage,
    resultPage: resultPage
  });
