import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from 'react-redux'

import { ROUTES } from './config/constants.js'
import Home from './pages/Home'
import Menu from './pages/Menu'
import Upload from './pages/Upload'
import Build from './pages/Build'
import Results from './pages/Results'
import store, { history } from "./store";
import Authorization from "./config/Authorization";


class App extends Component {
  render(){
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <BrowserRouter>
            <Switch>
              <Route path={ROUTES.HOME} component={Home} exact />
              <Route path={ROUTES.MENU} component={Menu} exact />
              <Route
                path={ROUTES.UPLOAD}
                component={Authorization(Upload)}
                exact
              />
              <Route
                path={ROUTES.BUILD}
                component={Authorization(Build)}
                exact
              />
              <Route
                path={ROUTES.RESULTS}
                component={Authorization(Results)}
                exact
              />
            </Switch>
          </BrowserRouter>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
