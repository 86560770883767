import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from 'axios'

import * as actions from "./action";
import Layout from "../../components/Layout";
import { API_BASE_URL } from "../../config/constants";
import './style.css';

class ConnectedResultsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultFile: null,
      chart1: {
        name: '',
        description: '',
        image: null,
      },
      chart2: {
        name: '',
        description: '',
        image: null,
      },
      otherInfo: '',
      customers: 0,
      locations: 0,
      products: 0,
      comments: '',
      months: 0,
    };
  }

  componentDidMount = () => {
    const { runID } = this.props
    this.setState({
      //resultFile: "/results/" + runID + "/data.zip"
    }, () => this.getResults())
  }

  getResults = () => {
    const { userDetails, runID } = this.props
    console.log(runID)
    const that = this
    axios({
      method: 'get',
      url: API_BASE_URL + '/results/' + runID,
      headers: {
        "Content-Type": 'application/json',
        token: userDetails.idToken,
      },
    })
      .then(function (response) {
        const result = response.data
        console.log(result)
        that.setResults(result)
      })
      .catch(error => console.log(error.message));
  }

  setResults = (result) => {
    const that = this
    const charts = result.charts
    this.setState({
      customers: result.customers,
      locations: result.locations,
      products: result.products,
      months: result.months,
      days: result.days,
      comments: result.comments,
      resultFile: result.results,
    })
    charts.forEach(function (chart) {
      if (chart.chardId === 1) {
        that.setState(prevState => ({
          chart1: {
            ...prevState.chart1,
            name: chart.name,
            description: chart.comments,
            image: chart.img
          }
        }))
      }
      if (chart.chardId === 2) {
        that.setState(prevState => ({
          chart2: {
            ...prevState.chart2,
            name: chart.name,
            description: chart.comments,
            image: chart.img
          }
        }))
      }
    })
  }

  render() {

    const ChartSection = ({ chart }) => {
      return (
        <div className="col-md-6">
          <h1 className="page-title">{chart.name}</h1>
          <img className="chart-img" src={chart.image} alt={chart.name} />
          <p className="chart-desc">
            {chart.description}
          </p>
        </div>
      );
    }

    return (
      <Layout>
        <div className="container">
          <div className="content-wrap">
            <div className="row page-heading">
              <div className="col-md-8">
                <h1 className="page-title">Here's what we've found</h1>
              </div>
              <div className="col-md-4 credits">
                <a href={this.state.resultFile} className="btn btn-primary" download>Download results</a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row top-results-row">
                  <div className="col-md-6">
                    <div className="results-sm-card">
                      <p className="result-num">{this.state.customers}</p>
                      <p className="result-type">customers</p>
                    </div>
                  </div>
                  <div className="col-md-6 right-results-col">
                    <div className="results-sm-card">
                      <p className="result-num">{this.state.locations}</p>
                      <p className="result-type">locations</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="results-sm-card">
                      <p className="result-num">{this.state.products}</p>
                      <p className="result-type">products</p>
                    </div>
                  </div>
                  <div className="col-md-6 right-results-col">
                    <div className="results-sm-card">
                      <p className="result-num">{this.state.days}</p>
                      <p className="result-type">days</p>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <p>This data also allows you to run the following use cases:</p>
                <a href="/menu" className="btn btn-primary btn-results-menu">What customers are likely to come back</a>
                <a href="#" className="btn btn-primary btn-results-menu disabled">Missing reference customers</a>
                <a href="#" className="btn btn-primary btn-results-menu disabled">Missing communication history</a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group results-other-info">
                  <label htmlFor="otherRelevantInfo"><b>Other Relevant Information</b></label>
                  <div className="rounded-0" id="otherRelevantInfo" rows="3" readonly="true">
                  {this.state.comments}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <ChartSection
                chart={this.state.chart1}
              />
              <ChartSection
                chart={this.state.chart2}
              />
            </div>
          </div>
        </div>
      </Layout>

    )
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.menuPage.userDetails,
    runID: state.resultPage.runID
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setRunID: data => {
      dispatch(actions.setRunID(data));
    }
  };
};

const ResultsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedResultsPage);

export default ResultsPage;
