import * as actions from "./action";

const initialState = {
  runID: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_RUN_ID:
      return {
        ...state,
        runID: action.payload
      };

    default:
      return state;
  }
}
