export const SET_DATA_DEFINITIONS = "SET_DATA_DEFINITIONS";
export const UPDATE_DATA_DEFINITIONS = "UPDATE_DATA_DEFINITIONS";

export const setDataDefinitions = dataDefinitions => dispatch => {
  dispatch({
    type: SET_DATA_DEFINITIONS,
    payload: dataDefinitions
  });
};

export const updateDataDefinitions = dataDefinitions => dispatch => {
  console.log(dataDefinitions);
  dispatch({
    type: UPDATE_DATA_DEFINITIONS,
    payload: dataDefinitions,
    id: dataDefinitions.id
  });
};
