import React, { Component } from "react";
import { connect } from "react-redux";
import * as AWSCognito from "amazon-cognito-identity-js";
import * as actions from "./action";
import axios from "axios";

import { AMAZON_COGNITO_POOLDATA, API_BASE_URL } from "../../config/constants";

import ModalLogin from "./components/ModalLogin";
import ModalComponent from "../../components/ModalComponent";
import ModalRegister from "./components/ModalRegister.js";
import UserCredits from "../../components/UserCredits";
import Layout from "../../components/Layout";

import menuA from "../../assets/images/menu-1.png";
import menuB from "../../assets/images/segmentation-assessment-icon-v3.png";
import menuC from "../../assets/images/menu-3.png";

import "./style.css";

class ConnectedMenuPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
      invalidUserPass: false,
      isExistingUser: false,
      loginModalShow: false,
      regModalShow: false,
      confirmModalShow: false,
      regError: false,
    };
    this.onChangeForm = this.onChangeForm.bind(this);
    this.onSubmitLoginForm = this.onSubmitLoginForm.bind(this);
    this.onSubmitRegForm = this.onSubmitRegForm.bind(this);
  }

  componentDidMount = () => {
    //this.setLogin();
    const { userDetails, setLatestUserDetail } = this.props;
    if(userDetails) {
      setLatestUserDetail(userDetails);
    }
  };

  onClickUpload = (modelType) => {
    if (this.props.userDetails) {
      this.props.history.push("/upload?modelType="+ modelType);
    } else {
      this.setState({
        loginModalShow: true
      });
    }
  };

  setLoginModalShow = () => {
    this.setState({
      loginModalShow: !this.state.loginModalShow,
      username: "",
      password: "",
      email: ""
    });
  };

  setRegModalShow = () => {
    this.setState({
      regModalShow: !this.state.regModalShow,
      username: "",
      password: "",
      email: ""
    });
  };

  setConfirmationModalShow = () => {
    this.setState({
      confirmModalShow: !this.state.confirmModalShow,
      loginModalShow: true,
      regModalShow: false
    });
  };

  onChangeForm(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onSubmitLoginForm(e) {
    this.login();
  }

  onSubmitRegForm(e) {
    this.register();
  }

  getUserCredits = (userTokens) => {
    const { setUserDetails } = this.props;
    let { username } = this.state
    const that = this
    console.log('fetching user profile')

    axios
      .get(API_BASE_URL + "/profile", {
        headers: {
          "Content-Type": "application/json",
          token: userTokens.idToken
        }
      })
      .then(function(response) {
        const data = response.data;
        console.log(data);
        let userDetails = {
          creditsUsed: data.creditsUsed,
          creditsAvailable: data.creditsAvailable,
          username: username,
          email: data.email
        };
        userDetails = { ...userDetails, ...userTokens };
        sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
        setUserDetails(userDetails);
        that.props.history.push('/upload')
      })
      .catch(error => console.log(error.message));
  };

  login = () => {
    let authenticationDetails = new AWSCognito.AuthenticationDetails({
      Username: this.state.username,
      Password: this.state.password
    });

    let userPool = new AWSCognito.CognitoUserPool(AMAZON_COGNITO_POOLDATA);
    let cognitoUser = new AWSCognito.CognitoUser({
      Username: this.state.username,
      Pool: userPool
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        console.log(result);
        let userTokens = {
          idToken: result.idToken.jwtToken,
          accessToken: result.accessToken.jwtToken,
          refreshToken: result.refreshToken.token
        };
        this.getUserCredits(userTokens);
        this.setState({ invalidUserPass: false });
      },
      onFailure: err => {
        console.log(err);
        this.setState({ invalidUserPass: true });
      }
    });
  };

  register = () => {
    let userPool = new AWSCognito.CognitoUserPool(AMAZON_COGNITO_POOLDATA);
    let attributeEmail = new AWSCognito.CognitoUserAttribute({
      Name: "email",
      Value: this.state.email
    });
    let attributeList = [];
    let that = this;
    attributeList.push(attributeEmail);

    userPool.signUp(
      this.state.username,
      this.state.password,
      attributeList,
      null,
      function(err, result) {
        if (err) {
          that.setState({ regError: true });
          return;
        }
        this.clearForm();
        this.setConfirmationModalShow();
      }
    );
  };

  clearForm = () => {
    this.setState({
      username: "",
      password: "",
      email: "",
      invalidUserPass: false,
      regError: false
    });
  };

  render() {
    const { userDetails } = this.props;
    return (
      <Layout>
        <div className="container">
          <div className="content-wrap ">
            <div className="row page-heading">
              <div className="col-md-8">
                <h1 className="page-title">Analyze Your Data</h1>
                <p className="page-desc">
                  Select one of your problems below to start analyzing data.
                </p>
              </div>
              {userDetails && (
                <UserCredits
                  creditsUsed={userDetails.creditsUsed}
                  creditsAvailable={userDetails.creditsAvailable}
                />
              )}
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <div className="menu-content text-center">
                      <img src={menuA} alt="" />
                      <p className="menu-text">
                        Who will buy my products if I connect with them?
                      </p>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.onClickUpload('churn')}
                  >
                    Upload
                  </button>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <div className="menu-content text-center">
                      <img src={menuB} alt="" />
                      <p className="menu-text">
                        Segment your customer base in actionable target groups?
                      </p>
                    </div>
                  </div>
                  <button
                      className="btn btn-primary"
                      onClick={() => this.onClickUpload('segmentation')}
                    >
                      Upload
                    </button>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card coming-soon">
                  <div className="card-body">
                    <div className="menu-content text-center">
                      <img src={menuC} alt="" />
                      <p className="menu-text">
                        How much will we sell next month?
                      </p>
                    </div>
                  </div>
                  <a href="/upload" className="btn btn-primary disabled">
                    Coming Soon
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLogin
          show={this.state.loginModalShow}
          onHide={this.setLoginModalShow}
          onChangeForm={this.onChangeForm}
          onSubmitLoginForm={this.onSubmitLoginForm}
          username={this.state.username}
          password={this.state.password}
          showRegModal={this.setRegModalShow}
          invalidUserPass={this.state.invalidUserPass}
        />

        <ModalRegister
          show={this.state.regModalShow}
          onHide={this.setRegModalShow}
          onChangeForm={this.onChangeForm}
          onSubmitRegForm={this.onSubmitRegForm}
          username={this.state.username}
          password={this.state.password}
          email={this.state.email}
          showLoginModal={this.setLoginModalShow}
          isExistingUser={this.state.isExistingUser}
          regError={this.state.regError}
        />
        <ModalComponent
          show={this.state.confirmModalShow}
          onHide={this.setConfirmationModalShow}
          heading={"Confirm your account."}
          body={
            "A confirmation link was sent to your email address. Login back here after confirming your account."
          }
        />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.menuPage.userDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUserDetails: data => {
      dispatch(actions.setUserDetails(data));
    },
    setLatestUserDetail: data => {
      dispatch(actions.setLatestUserDetail(data));
    }
  };
};

const MenuPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedMenuPage);

export default MenuPage;
