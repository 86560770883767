import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import axios from 'axios'
import * as actions from "./action";

import Layout from "../../components/Layout";
import { API_BASE_URL } from "../../config/constants";
import './style.css';
import { getUrlParameterByName } from "../../utils/UrlUtil";


class ConnectedBuildPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
      stage1: {
        name: '',
        status: '',
        log: [],
      },
      stage2: {
        name: '',
        status: '',
        log: [],
      },
      stage3: {
        name: '',
        status: '',
        log: [],
      },
      stage4: {
        name: '',
        status: '',
        log: [],
      },
    };
  }

  componentDidMount = () => {
    const runId = getUrlParameterByName('runId')
    const { setRunID } = this.props
    if(!runId) {
      console.log("=== startCrunching ===");
      this.startCrunching();
    } else {
      console.log("=== getCrunchingStatus ===");
      setRunID(runId).then(() => {
        this.getCrunchingStatus()
      });
    }
  }

  startCrunching = () => {
    const { userDetails, setRunID } = this.props
    const productId = getUrlParameterByName('productId');
    let modelType = getUrlParameterByName('modelType');
    let queryString = "";
    if(productId) {
      queryString = `?productid=${productId}`
    }
    if(!modelType) {
      modelType = 'churn' //backup for historical reaonsons
    }
    console.log(userDetails)
    const that = this
    axios({
      method: 'post',
      url: API_BASE_URL + '/submitRun/'+ modelType + queryString,
      headers: {
        "Content-Type": 'application/json',
        token: userDetails.idToken,
      },
    })
      .then(function (response) {
        console.log(response.data)
        sessionStorage.setItem("runID", response.data.runId);
        setRunID(response.data.runId).then(() => {
          that.getCrunchingStatus()
        });
        window.history.pushState( {} , '', `?runId=${response.data.runId}` );
      })
      .catch(error => console.log(error.message));
  }

  getCrunchingStatus = () => {
    const { userDetails, runID } = this.props
    const that = this

    axios({
      method: 'get',
      url: API_BASE_URL + '/status/' + runID,
      headers: {
        "Content-Type": 'application/json',
        token: userDetails.idToken,
      },
    })
      .then(function (response) {
        console.log(response.data)
        let floatPercent = parseFloat(response.data.percentage)
        that.setState({
          percentage: floatPercent
        })
        that.setStageDetails(response.data.stagedetails);
        if (floatPercent < 100) {
          setTimeout(that.getCrunchingStatus, 1000);
        }
      })
      .catch(error => console.log(error.message));
  }

  setStageDetails = (stageDetails) => {
    const that = this
    if (stageDetails) {
      stageDetails.forEach(function (stage) {
        if (stage.stageID === 1) {
          that.setState(prevState => ({
            stage1: {
              ...prevState.stage1,
              name: stage.name,
              status: stage.status,
              log: stage.log,

            }
          }))
        }
        if (stage.stageID === 2) {
          that.setState(prevState => ({
            stage2: {
              ...prevState.stage2,
              name: stage.name,
              status: stage.status,
              log: stage.log,

            }
          }))
        }
        if (stage.stageID === 3) {
          that.setState(prevState => ({
            stage3: {
              ...prevState.stage3,
              name: stage.name,
              status: stage.status,
              log: stage.log,

            }
          }))
        }
        if (stage.stageID === 4) {
          that.setState(prevState => ({
            stage4: {
              ...prevState.stage4,
              name: stage.name,
              status: stage.status,
              log: stage.log,

            }
          }))
        }
      });
    }
  }

  render() {
    const progressBarStyle = {
      width: this.state.percentage + '%'
    };

    const ResultsButton = ({ percentage }) => {
      if (percentage === 100) {
        return (
          <Link
            to={{
              pathname: '/results',
              state: {
                runID: this.state.runID
              }
            }}
            className="btn btn-primary"
          >Results</Link>
        );
      } else {
        return (
          <Link to='/results' className="btn btn-primary disabled">Results</Link>
        );
      }
    };

    const StageSection = ({ stage }) => {
      return (
        <div className="col-md-6">
          <div className="buildai-card-heading">
            <p className="buildai-card-title">
              {stage.name}
            </p>
            <p className="buildai-card-progress logs">
              {stage.status}
            </p>
          </div>
          <div className="buildai-card">
            <div className="buildai-card-body">
              {stage.log.map((l, key) => {
                return (
                  <p className="logs" key={key}>{l}</p>
                )
              })}
            </div>
          </div>
        </div>
      );
    }

    return (
      <Layout>
        <div className="container">
          <div className="content-wrap">
            <div className="row page-heading">
              <div className="col-md-8">
                <h1 className="page-title">Building your AI</h1>
                <p className="page-desc">Please wait as we crunch your data.</p>
              </div>
            </div>
            <div className="row progress-row">
              <div className="col">
                <div className="progress">
                  <div className="progress-bar" style={progressBarStyle}> </div>
                </div>
                <div className="progress-percent-div">
                  <span className="progress-percent">{this.state.percentage}%</span>
                </div>
              </div>
            </div>
            <div className="row">
              <StageSection
                stage={this.state.stage1}
              />
              <StageSection
                stage={this.state.stage2}
              />
            </div>
            <div className="row">
              <StageSection
                stage={this.state.stage3}
              />
              <StageSection
                stage={this.state.stage4}
              />
            </div>
            <ResultsButton
              percentage={this.state.percentage}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.menuPage.userDetails,
    runID: state.buildPage.runID
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setRunID: data => {
      console.log(data)
      dispatch(actions.setRunID(data));
      return Promise.resolve();
    }
  };
};

const BuildPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedBuildPage);

export default BuildPage;
