import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { ROUTES } from "./constants.js";
import * as actions from '../pages/Menu/action';

const Authorization = (WrappedComponent) => {
    class WithAuthorization extends Component {

        componentDidMount = () => {
            const { setLatestUserDetail } = this.props;
            console.log('auth running');

            // Check if currrent user is logged in.
            //  else, fetch user
            if (sessionStorage.userDetails) {
              console.log("Has sessionStorage");
              setLatestUserDetail(JSON.parse(sessionStorage.userDetails));
              this.setState({ isLoading: false });
            } else {
                console.log("Has no sessionStorage");
            }
        };

        render() {
            const { userDetails } = this.props;
            const sessionUser = sessionStorage.userDetails;
          if (sessionUser || (userDetails && Object.entries(userDetails).length !== 0)) {
              console.log("logged in");
              return <WrappedComponent {...this.props} />;
            } else {
              return <Redirect to={ROUTES.HOME} {...this.props} />;
            }
        }
    }

    const mapStateToProps = state => {
        return { userDetails: state.menuPage.userDetails };
    };

    const mapDispatchToProps = dispatch => {
        return {
          setLatestUserDetail: data => {
            dispatch(actions.setLatestUserDetail(data));
          }
        };
    };

    return connect(
        mapStateToProps,
        mapDispatchToProps,
    )(WithAuthorization);
};

export default Authorization;
