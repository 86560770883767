import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

class ModalProductList extends Component {

    customStyles = {
        menuList: (provided, state) => ({
            color: "black"
        }),
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={false}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title className="modal-title" id="contained-modal-title-vcenter">
                        Select Product
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                    <div>One or more products you want us to optimize for</div>
                    <Select
                        isMulti
                        name="colors"
                        options={this.props.selectOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.props.handleSelectChange}
                        styles={this.customStyles}
                    />
                    </div>
                    <div className="form-group">
                        <button
                        className="btn btn-primary btn-block"
                        onClick={this.props.onClickOk}
                        >
                            Ok
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }
}
export default ModalProductList;