import React from 'react';
import NavbarComponent from '../components/NavbarComponent';
import FooterComponent from '../components/FooterComponent';
import Particles from "react-particles-js";
import { particlesOptions } from "../config/constants";
import "../assets/styles/components/particles.css";
import "../App.css";

const Layout = (props) => {
    return (
      <>
        <NavbarComponent />
            <Particles className="particles" params={particlesOptions} />
            {props.children}
        <FooterComponent />
      </>
    );
}

export default Layout