import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createRootReducer from './rootReducer';

const initialState = {};
const middleware = [thunk];

export const history = createBrowserHistory();

// enable trace for redux devtools
// { trace: true, traceLimit: 25 }
const composeEnhancers = composeWithDevTools({});

const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...middleware)),
);

export default store;
