import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import * as actions from "./action";
import ModalProductList from "./components/ModalProductList";

import Layout from "../../components/Layout";
import UserCredits from "../../components/UserCredits";
import UploadBox from './components/UploadBox'
import { API_BASE_URL, INITIAL_UPLOAD_BOX } from "../../config/constants";
import { getUrlParameterByName } from "../../utils/UrlUtil";
import "./style.css";

class ConnectedUploadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      models: [],
      productModalShow: false,
      selectedOption: [],
      selectOptions: [],
      selectedProductIds : "",
      modelType: ""
    };
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  componentDidMount = () => {
    //this.getModels();
    this.getModelInfo();
    const modelType = getUrlParameterByName("modelType");
    if(this.isSupportedModelType(modelType)) {
      this.setState({ modelType });
    } else {
      this.props.history.push("/menu");
    }
  };

  isSupportedModelType = (modelType) => {
    const supportModelType = ['churn', 'segmentation']
    return supportModelType.some((type) => {
      return modelType === type;
    });
  }

  handleSelectChange = (selectedOption) => {
    if(selectedOption) {
      let selectOptions = [];
      selectedOption.map( selected => {
        selectOptions.push(selected.value);
      });
      this.setState({ selectedProductIds: selectOptions.join(',')})
    } else {
      this.setState({ selectedProductIds: ""})
    }
  }

  showProductModal = (selectOptions) => {
    this.setState({ productModalShow: true, selectOptions });
  };

  hideProductModal = () => {
    this.setState({ productModalShow: false });
  };

  onClickOk = () => {
    if (this.state.selectedProductIds) {
      this.setState({ productModalShow: false });
    }
  };

  getModels = () => {
    const { token } = this.state;
    axios
      .get(API_BASE_URL + "/models", {
        headers: {
          "Content-Type": "application/json",
          token: token
        }
      })
      .then(function(response) {
        console.log(response)
      })
      .catch(error => console.log(error.message));
  };

  getModelInfo = () => {
    const { userDetails, setDataDefinitions } = this.props
    const { modelType } = this.state;

    axios
      .get(API_BASE_URL + `/models/${modelType}/datadefinitions`, {
        headers: {
          "Content-Type": "application/json",
          token: userDetails.idToken
        }
      })
      .then(function(response) {
        console.log(response);
        let data = response.data.datatypes;
        let definitions = {}
        data.forEach(function(d) {
          definitions[d.id] = { ...d, ...INITIAL_UPLOAD_BOX };
        });
        setDataDefinitions(definitions);
      })
      .catch(error => console.log(error.message));
  };

  onChangeFile(e) {
    const file = e.target.files[0];
    const id = e.target.id;
    const { updateDataDefinitions } = this.props
    let newState = {
      id: id,
      file: file,
      filename: file.name,
      status: "Verifying your file...",
      message: "",
      classCard: "upload-card-body",
      classBorder: "upload-status-uploading",
      uploaded: false
    }
    updateDataDefinitions(newState).then(() => {
      this.uploadFile(id)
    });
  }

  uploadFile = id => {
    const { userDetails, dataDefinitions } = this.props;
    const { modelType } = this.state;    
    const selectedFile = dataDefinitions[id];
    let that = this;
    console.log(id)
    //upload file
    axios({
      method: "get",
      url: API_BASE_URL + `/dataupload/${id}/${modelType}`,
      headers: {
        "Content-Type": "application/json",
        token: userDetails.idToken
      }
    })
          .then(function(response) {
            //now send request to the new location
            const formData = new FormData();
            Object.keys(response.data.fields).forEach(key => {
              formData.append(key, response.data.fields[key]);
            });
            // Actual file has to be appended last.
            formData.append("file", selectedFile.file);
             const xhr = new XMLHttpRequest();
            xhr.open("POST", response.data.url, true);
            xhr.send(formData);
            xhr.onload = function() {
              this.status === 204 ? that.validateData(id) : console.log(this.message);
            };


            //axios({
            //  method: 'post',
            //  url: response.url,
            //  headers: {
            //    'Content-Type': 'application/json',
            //    'token': token,
            //  },
            //  data: selectedFile.file,
            //})
            //  .then(function() {
            //
            //  })
            //  .catch(error => console.log(error.message));
      });
  }

  validateData = id => {
    const { userDetails } = this.props;
    const { modelType } = this.state;
    let that = this;
    axios({
      method: "get",
      url: API_BASE_URL + `/datavalidation/${id}/${modelType}`,
      headers: {
        "Content-Type": "application/json",
        token: userDetails.idToken
      }
    })
      .then(function(response) {
        console.log(response.data);
        that.setInputStatus(response.data, id);
      })
      .catch(error => console.log("Authorization failed : " + error.message));
  };

  setInputStatus = (response, id) => {
    const { updateDataDefinitions } = this.props
    const result = response.result;
    if (result === "CHECKING") {
      setTimeout(this.validateData, 2000, id);
    } else if (result === "FAIL") {
      let newState = {
        id: id,
        status: "There is something wrong with your uploaded file...",
        message: "Error/s: " + response.errors.join(),
        classCard: "upload-card-body-error",
        classBorder: "upload-status-error",
        uploaded: false
      }
      updateDataDefinitions(newState)
    } else if (result === "OK") {
      let newState = {
        id: id,
        status: "File uploaded and verified successfully.",
        message: "Warning/s: " + response.warnings.join(),
        classCard: "upload-card-body-success",
        classBorder: "upload-status-success",
        uploaded: true
      }
      if(id === "sales") {
        if (response.select_products) {
          if (response.select_products.length > 0) {
            let options = [];
            response.select_products.map( value => {
              options.push({
                value,
                label: value
              })
            });
            this.setState({ productModalShow: true, selectOptions: options });
          } 
        } 
      }
      updateDataDefinitions(newState)
    }
  };

  render() {
    const { userDetails, dataDefinitions } = this.props;
    const { modelType } = this.state;
    
    let buildUrl = `/build?modelType=${modelType}`;
    if (this.state.selectedProductIds) {
      buildUrl = buildUrl + `&productId=${this.state.selectedProductIds}`
    }

    console.log(dataDefinitions)
    const RunButton = () => {
      if (Object.keys(dataDefinitions).every(data => dataDefinitions[data].uploaded === true)) {
        return <a href={buildUrl} className="btn btn-primary">Run AI</a>
      }
      return <a href={buildUrl} className="btn btn-primary disabled">Run AI</a>
    };
    const uploadButton = Object.keys(dataDefinitions).map(key => (
      <UploadBox
        data={dataDefinitions[key]}
        onChangeFile={this.onChangeFile}
        key={key}
      />
    ));

    return (
      <Layout>
        <div className="container">
          <div className="content-wrap">
            <div className="row page-heading">
              <div className="col-md-8">
                <h1 className="page-title">Upload the relevant data</h1>
                <p className="page-desc">
                  Please upload the required data so we can start crunching it.
                  <br />
                  For more info visit Documentation. (top right)
                </p>
              </div>
              {userDetails && (
                <UserCredits
                  creditsUsed={userDetails.creditsUsed}
                  creditsAvailable={userDetails.creditsAvailable}
                />
              )}
            </div>
            <div className="row">{uploadButton}</div>
            <RunButton />
            <ModalProductList
              show={this.state.productModalShow}
              onHide={this.hideProductModal}
              onClickOk={this.onClickOk}
              selectedOption={this.state.selectedOption}
              selectOptions={this.state.selectOptions}
              handleSelectChange={this.handleSelectChange}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    dataDefinitions: state.uploadPage.dataDefinitions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDataDefinitions: data => {
      dispatch(actions.setDataDefinitions(data));
    },
    updateDataDefinitions: data => {
      dispatch(actions.updateDataDefinitions(data));
      return Promise.resolve();
    }
  };
};

const UploadPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedUploadPage);

export default UploadPage;
