import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Input from '../../../components/InputComponent'
//import GoogleLogin from 'react-google-login';
//import { GOOGLE_CLIENT_ID } from '../config/constants'

class ModalRegister extends Component {
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title className="modal-title" id="contained-modal-title-vcenter">
                        Register your account.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.props.isExistingUser && <label className="err">This account already exists.</label> }
                    { this.props.regError && <label className="err">Password must be at least 6 characters long and have at least one lowercase, uppercase, digit, and a special character.</label> }
                    <div className="form-group">
                        <Input
                            type="username"
                            className="login-field"
                            name="username"
                            placeholder="Username"
                            required="required"
                            onChange={this.props.onChangeForm}
                            value={this.props.username}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            type="email"
                            className="login-field"
                            name="email"
                            placeholder="Email Address"
                            required="required"
                            onChange={this.props.onChangeForm}
                            value={this.props.email}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            type="password"
                            className="login-field"
                            name="password"
                            placeholder="Password"
                            required="required"
                            onChange={this.props.onChangeForm}
                            value={this.props.password}
                        />
                    </div>
                    <div className="form-group">
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            onClick={this.props.onSubmitRegForm}
                        >
                            Register
                        </button>
                    </div>
                    <div className="text-center">Already have an account <a href="#" id="login" onClick={this.props.showLoginModal}>Login here</a></div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }
}
export default ModalRegister;