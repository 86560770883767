import axios from "axios";

import { API_BASE_URL } from "../../config/constants";

export const SET_USER_DETAILS = "SET_USER_DETAILS";

export const setUserDetails = (userDetails) => (dispatch) => {
  dispatch({
    type: SET_USER_DETAILS,
    payload: userDetails,
  });
};

export const setLatestUserDetail = (userDetails) => (dispatch) => {
  axios
    .get(API_BASE_URL + "/profile", {
      headers: {
        "Content-Type": "application/json",
        token: userDetails.idToken,
      },
    })
    .then(function (response) {
      console.log("fetch the latest user details");
      const data = response.data;
      let updateUserDetails = {
        creditsUsed: data.creditsUsed,
        creditsAvailable: data.creditsAvailable,
        username: userDetails.username,
        email: data.email,
      };
      updateUserDetails = { ...updateUserDetails, ...userDetails };
      sessionStorage.setItem("userDetails", JSON.stringify(updateUserDetails));
      dispatch(setUserDetails(updateUserDetails));
    })
    .catch((error) => console.log(error.message));
};
