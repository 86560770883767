import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../assets/images/logo.svg";
import ModalComponent from "./ModalComponent";

const howItWorksTitle = "How it Works";
const howItWorksBody =
  `
Plug&Play AI leverages on machine learning techniques to build an artificial intelligence for each use case. At the very first step, it makes sense of the data that’s provided. It does this by taking into account minimum data requirements, as well as optional data accelerators. Doing so it builds a first level understanding around the problem.
<br /> <br />
Afterwards the heavy lifting starts. In the second step best in class insights are uncovered and signals are being uncovered from the noise. The Artificial Intelligence engine is built up from a combination of different market and competitor insights gathered across 10 years of experience. This allows it to speed up further compared to traditional data science. 
<br /> <br />
Once the data insights are uncovered, different types of algorithms are built out and challenged against each other. This allows to find out what type of algorithm works best for your data. The algorithms combine the data and insights to uncover patterns. Rather than measuring the algorithms on theoretical scores, they’re validated in terms of business value.
<br /> <br />
In the final step the best algorithm is applied on the data provided and patterns are translated to human insights. This allows the marketeer to easily understand what to do next as well as have the information ready in case a presentation needs to be held.
<br /> <br />
We care about data security and privacy. Therefore, we fully encrypt all data and PII data is removed. In the end, the algorithm doesn’t need this information any way. After each run, the machines that have touched the data are destroyed to make sure no one can touch your data.
<br /> <br />
Are you wondering how your business can gain better bottom line results?
 <br />
 <b> Select a problem and upload your data! </b>

      `;
const aboutTitle = "About";
const aboutBody =
  `
Plug & Play AI is an Artificial Intelligence company based in Singapore that provides marketeers & business analysts with the next generation of problem-solving capabilities to improve daily data-oriented tasks.
The company was founded in 2020 by 2 friends that specialize in Artificial Intelligence and Big Data. 
<br /> <br />
Together they combine over 20 years of experience in these fields and solved problems for the biggest retailers and banks in the world across 3 different continents.
Throughout their career they noticed that the same problems reoccurred over and over, and companies were overcharged for their services. Next to that they noticed that AI and Big Data became a game of the big firms and experts. SME’s and less mature players are not able to compete, traditional marketeers and analysts didn’t have the skills to get started. To solve that problem, they build out Plug & Play AI: An automated AI engine that solve the core data-oriented tasks of marketeers and analysts leveraging years of experience and best practices. In doing so liberating them from excel sheets and reports in order to accelerate their job towards real business impact.
<br /> <br />
Are you wondering how your job can become easier?
<br />
<b>Select a problem and upload your data! </b>
`;
const documentationTitle = "Documentation";
const documentationBody =
  `
   Plug & Play AI analyzes your data to help you create better choices. To use it, user only needs to select the problem to solve, upload the required files, let the AI run and wait for the results.

<br /><br />

   <b>Data requirements</b><br />
   Data can come in any format. While CSV format is easier to guarantee data integrity, other formats such as MS Excel and TXT are not an issue for engine. If your data is in a system, we advise you not to open the file before uploading since certain programs might try to layout it and make the data harder to read.
<br /><br />
   <b>Data size</b><br />
   Depending on the data size and the problem the AI engine will make sure that enough compute power is available. So you don't need to worry about your files being to big or having too many records. As a rule of thumb, the more data, the better.
   We do advise to provide enough sample from for the engine to learn from. Try to provide at least 1000 customer data points and at least 3 months of data. With less data you could just as well do it by hand.
<br /><br />
   <b>Column names</b><br />
   Column names do not need to follow a certain format. The AI engine looks at the actual data points to understand what data is provided. If you want the process to run faster, we will suggest a couple of options below
<br /><br />
   <b>Data points</b><br />
   CUSTOMER INFO: The goal of this file is to make sure that the final output provided to you is usable. We advise you to provide a customer ID, loyalty ID and contact point (e.g. email address, phone number, WeChat ID). This is important in case a customer can have multiple loyalty number or contact number. In case you miss some of the information, just leave it blank
<br />
    <b>Example:</b><br />
   CUSTOMER NO | CARD ID | EMAILADRESS
   Dsj107917s2 | 291832719 | john@plugnplayai.com
<br /><br />
   <b>PAST SALES INFO PER PRODUCT</b>: the goal of this file is to find out how much was sold of each product, to each customer. It is important that there is a customer id, date, product id, value and quantity to this data in order for the AI engine to uncover patterns. Any additional information such as product descriptions and store information will aid to find deeper insights.
<br />
   <b>Example:</b><br />
   CARD ID | DATE | PRODUCT ID | PRODUCT DESCRIPTION | VALUE | QUANITITY | STORE
   291832719 | 05-01-2019 | 123 | MILK | 7.32 | 1
   | DOWN TOWN
   `;

class NavbarComponent extends Component {
  state = {
    modalShow: false,
    modalTitle: "",
    modalBody: "",
    navbarBg: "transparent",
    isTop: true
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10) {
        this.setState({ navbarBg: "" });
      } else {
        this.setState({ navbarBg: "transparent" });
      }
    });
  }

  setHowItWorksModalShow = () => {
    this.setState({
      modalShow: true,
      modalTitle: howItWorksTitle,
      modalBody: howItWorksBody
    });
  };

  setAboutModalShow = () => {
    this.setState({
      modalShow: true,
      modalTitle: aboutTitle,
      modalBody: aboutBody
    });
  };

  setDocumentationModalShow = () => {
    this.setState({
      modalShow: true,
      modalTitle: documentationTitle,
      modalBody: documentationBody
    });
  };

  setDocumentationModalShow = () => {
    this.setState({
      modalShow: true,
      modalTitle: documentationTitle,
      modalBody: documentationBody
    });
  };

  setModalShow = () => {
    this.setState({
      modalShow: false
    });
  };

  setLogoutModalShow = () => {

  }

  onClickLogout = (e) => {
    sessionStorage.clear()
    window.location.href = "/";
  }

  logoutButton() {
    const { userDetails } = this.props;
    if (userDetails && Object.entries(userDetails).length !== 0)  {
      return (
        <Nav.Link className="navbar-link" onClick={this.onClickLogout}>
          Logout
        </Nav.Link>
      );
    }
  }

  render() {
    return (
      <div>
        <ModalComponent
          show={this.state.modalShow}
          onHide={this.setModalShow}
          heading={this.state.modalTitle}
          body={this.state.modalBody}
        />
        <Navbar variant="dark" bg={this.state.navbarBg} expand="lg">
          <Navbar.Brand href="/">
            <img src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav ml-auto">
              <Nav.Link className="navbar-link" href="/">
                Home
              </Nav.Link>
              <Nav.Link
                className="navbar-link"
                onClick={this.setHowItWorksModalShow}
              >
                How it Works
              </Nav.Link>
              <Nav.Link
                className="navbar-link"
                onClick={this.setAboutModalShow}
              >
                About
              </Nav.Link>
              <Nav.Link
                className="navbar-link"
                onClick={this.setDocumentationModalShow}
              >
                Documentation
              </Nav.Link>
             {this.logoutButton()}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  userDetails: state.menuPage.userDetails
});

export default connect(mapStateToProps)(NavbarComponent);
