import * as actions from "./action";

const initialState = {
  dataDefinitions: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DATA_DEFINITIONS:
      return {
        ...state,
        dataDefinitions: action.payload
      };

    case actions.UPDATE_DATA_DEFINITIONS:
      let id = action.id;
      return {
        ...state,
        dataDefinitions: {
          ...state.dataDefinitions,
          [id]: action.payload
        }
      }
    default:
      return state;
  }
}
