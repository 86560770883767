import React, { Component } from "react";

class FooterComponent extends Component {
    render() {
        return(
            <footer className="footer">
                {/* <p>© 2019 Plug&Play AI. All Rights Reserved.</p> */}
            </footer>
        )
    }
}

export default FooterComponent;