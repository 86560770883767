import React, { Component } from "react";
import { connect } from "react-redux";

import Layout from "../../components/Layout"
import "./style.css";

class HomePage extends Component {
  render() {
    return (
      <Layout>
        <div className="flex">
          <div className="container content-wrap">
            <h1 className="hero-text">
              GET TO KNOW YOUR <br /> CUSTOMERS BETTER
            </h1>
            <div className="copy-text">
              <p>
                Plug and Play is a technology powered by artificial intelligence
                developed for marketing specialists to analyze data from their
                customers and clients who use thier technology. This app aims to
                provide a seamless presentation of data gathered from the
                system.
              </p>
            </div>
            <a href="/menu" className="btn btn-primary">
              Analyze your Data
            </a>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.menuPage.userDetails
  };
};

export default connect(mapStateToProps)(HomePage);
