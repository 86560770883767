import React, { Component } from "react";

class Input extends Component {
    render() {
        return (
            <input
                type={this.props.type}
                className={"form-control " + this.props.className}
                name={this.props.name}
                placeholder={this.props.placeholder}
                required="required"
                onChange={this.props.onChange}
                value={this.props.value}
            >
            </input>
        )
    }
}

export default Input;