import React, { Component } from "react";
import { Modal, Button } from 'react-bootstrap';

class ModalComponent extends Component {

   componentDidUpdate() {
        if( (NodeList.prototype.isPrototypeOf(document.querySelectorAll(".modelBody")) && document.querySelectorAll(".modelBody").length) )  {
            document.querySelectorAll(".modelBody")[0].innerHTML= document.querySelectorAll(".modelBody")[0].innerText;
        }
   }

    render() {
        return(
        <Modal
            show={this.props.show}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title className="modal-title" id="contained-modal-title-vcenter">
                    {this.props.heading}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-desc">
                <div className="modelBody">{this.props.body}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
        )
    }


}

export default ModalComponent;