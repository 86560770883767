
import React from 'react';
import "../style.css";

const UploadBox = ({
  onChangeFile,
  data
}) => {
  return (
    <div className="col-md-8">
      <div className="upload-card">
        <div className={data.classBorder}>
          <p>{data.status}</p>
        </div>
        <div className={data.classCard}>
          <div className="upload-content">
            <p className="upload-text">{data.description}</p>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id={data.id}
                name={data.name}
                onChange={onChangeFile}
              />
              <label className="custom-file-label">
                {data.filename !== ""
                  ? data.filename
                  : "Choose file to upload."}
              </label>
            </div>
            <p className="upload-reminder">{data.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadBox;