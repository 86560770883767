import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Input from '../../../components/InputComponent'
//import GoogleLogin from 'react-google-login';
//import { GOOGLE_CLIENT_ID } from '../config/constants'

class ModalLogin extends Component {
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title className="modal-title" id="contained-modal-title-vcenter">
                        Login to your Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.invalidUserPass && <label className="err">Invalid username or password.</label> }
                    <div className="form-group">
                        <Input
                            type="text"
                            className="login-field"
                            name="username"
                            placeholder="Username"
                            required="required"
                            onChange={this.props.onChangeForm}
                            value={this.props.username}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            type="password"
                            className="login-field"
                            name="password"
                            placeholder="Password"
                            required="required"
                            onChange={this.props.onChangeForm}
                            value={this.props.password}
                        />
                    </div>
                    <div className="form-group">
                        <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        onClick={this.props.onSubmitLoginForm}
                        >
                            Login
                        </button>
                    </div>
                    {/*
                    <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        render={renderProps => (
                            <button
                                className="btn btn-primary btn-block"
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >Sign In using Google
                            </button>
                        )}
                        buttonText="Login"
                        onSuccess={this.props.successResponseGoogle}
                        onFailure={this.props.errResponseGoogle}
                        cookiePolicy={'single_host_origin'}
                    /> */}
                    <div className="text-center">Don't have an account yet? <a href="#" id="register" onClick={this.props.showRegModal}>Register here</a></div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }
}
export default ModalLogin;