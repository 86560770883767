import * as actions from "./action";

const initialState = {
  userDetails: JSON.parse(sessionStorage.getItem('userDetails'))
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      };
    default:
      return state;
  }
}
